<script setup>
import { $createScopingPhoto, $deleteScopingPhoto, $getScopingPhotos } from '@/api/signs'
import { $createKopScopingPhoto, $deleteKopScopingPhoto, $getKopScopingPhotos } from '@/api/kop'
import { useUserStore } from '@/store/user'
import { useDrawingStore } from '@/store/drawing'
import { useSnackbarStore } from '@/store/snackbar'

const props = defineProps({
  kop: { type: Boolean, default: false },
  signId: { type: Number, default: null },
  kopItemId: { type: Number, default: null },
  canAdd: { type: Boolean, default: true },
  canDelete: { type: Boolean, default: true }
})

const userStore = useUserStore()
const drawingStore = useDrawingStore()
const snackbarStore = useSnackbarStore()

const photos = ref([])
const photosLoading = ref(false)
const selectedPhotoType = ref(0)
// Reactive object to track hover state for each photo
const hoveredPhotoStates = ref({})

// Photo Full Screen
const showImageFullScreen = ref(false)
const imageFullScreenUrl = ref(null)

const photoToAdd = ref(null)
const uploadLoading = ref(false)

const getScopingPhotos = async () => {
  photosLoading.value = true
  try {
    let res
    if (props.kop) {
      if (!props.kopItemId) {
        photosLoading.value = false
        return
      }
      res = await $getKopScopingPhotos({
        kop_item: props.kopItemId,
        photo_type: selectedPhotoType.value
      })
    }
    else {
      if (!props.signId) {
        photosLoading.value = false
        return
      }
      res = await $getScopingPhotos({
        sign: props.signId,
        photo_type: selectedPhotoType.value
      })
    }
    photos.value = res.data.results
    photosLoading.value = false
  } catch (e) {
    console.log(e)
  }
}

const addPhoto = async () => {
  uploadLoading.value = true
  const form = new FormData()
  form.append('source', photoToAdd.value[0])
  form.append('photo_type', selectedPhotoType.value)
  try {
    if (props.kop) {
      form.append('kop_item', props.kopItemId)
      await $createKopScopingPhoto(form)
    } else {
      form.append('sign', props.signId)
      await $createScopingPhoto(form)
    }
    await getScopingPhotos()
    photoToAdd.value = null
    snackbarStore.showMessage({
      color: 'success',
      icon: 'mdi-checkbox-marked-circle',
      title: 'Success',
      text: `${selectedPhotoType.value === 0 ? 'Scoping' : 'Installation'} Photo added successfully!`
    })
  } catch (e) {
    console.log(e)
    snackbarStore.showMessage({
      color: 'error',
      icon: 'mdi-alert-circle',
      title: 'Error',
      text: 'Oops, something went wrong, try again'
    })
  } finally {
    uploadLoading.value = false
  }
}


const deletePhoto = async (photo) => {
  try {
    const endpoint = props.kop ? $deleteKopScopingPhoto : $deleteScopingPhoto
    await endpoint(photo.id)
    await getScopingPhotos()
    snackbarStore.showMessage({
      color: 'success',
      icon: 'mdi-checkbox-marked-circle',
      title: 'Success',
      text: `${selectedPhotoType.value === 0 ? 'Scoping' : 'Installation'} Photo deleted successfully!`
    })
  } catch (e) {
    console.log(e)
    snackbarStore.showMessage({
      color: 'error',
      icon: 'mdi-alert-circle',
      title: 'Error',
      text: 'Oops, something went wrong, try again'
    })
  }
}

watch(selectedPhotoType, () => getScopingPhotos())

onMounted(() => {
  getScopingPhotos()
})
</script>

<template>
  <VContainer
    fluid
    class="pl-0 py-0"
    style="max-height: 100%; overflow-y: scroll;"
  >
    <ImageFullScreen
      v-model="showImageFullScreen"
      :image-url="imageFullScreenUrl"
    />
    <VRow class="mt-1">
      <VCol cols="12">
        <VSelect
          v-model="selectedPhotoType"
          :items="[{value: 0, title: 'Scoping'}, {value: 1, title: 'Installation'}]"
        />
      </VCol>
      <template v-if="!userStore.isCustomer">
        <VCol
          cols="9"
          class="py-0 pr-0"
        >
          <VFileInput
            v-model="photoToAdd"
            label="Upload Photo"
          />
        </VCol>
        <VCol
          cols="3"
          class="d-flex align-center justify-end py-0"
        >
          <VBtn
            class="py-1"
            :disabled="!photoToAdd || !canAdd || !!uploadLoading"
            @click="addPhoto"
          >
            Add
            <VProgressCircular
              v-if="uploadLoading"
              class="ml-1"
              size="x-small"
              :width="2"
              indeterminate
              color="light"
            />
          </VBtn>
        </VCol>
      </template>
    </VRow>
    <div class="position-relative">
      <VOverlay
        v-model="photosLoading"
        persistent
        absolute
        contained
        class="align-center justify-center"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VOverlay>
      <VRow
        v-if="photos.length"
        class="mt-4"
      >
        <VCol
          v-for="(photo, photosKey) in photos"
          :key="photosKey"
          class="scoping-photo-wrapper p-2"
          cols="12"
        >
          <div
            v-if="!userStore.isCustomer && canDelete"
            class="d-flex justify-end"
          >
            <VBtn
              icon="tabler-x"
              size="x-small"
              class="rounded-sm delete-scoping-photo-btn"
              @click="deletePhoto(photo)"
            >
              <VIcon icon="tabler-x" />
              <VMenu
                activator="parent"
                location="top center"
                open-on-hover
                open-delay="100"
                close-delay="0"
                offset="5"
                class="default-tooltip"
              >
                <span class="px-2">
                  Delete {{ selectedPhotoType === 0 ? 'scoping' : 'installation' }} photo
                </span>
              </VMenu>
            </VBtn>
          </div>
          <div
            class="scoping-photo-container"
            @mouseenter="hoveredPhotoStates[photosKey] = true"
            @mouseleave="hoveredPhotoStates[photosKey] = false"
            @click="imageFullScreenUrl = photo.source; showImageFullScreen = true"
          >
            <img
              class="scoping-photo-img"
              :class="{ hovered: hoveredPhotoStates[photosKey] }"
              :src="photo.source"
              alt="img"
            />
            <VIcon
              v-if="hoveredPhotoStates[photosKey]"
              class="magnify-icon"
              icon="tabler-zoom-in"
              color="white"
            />
          </div>
        </VCol>
      </VRow>
      <VRow
        v-else
        class="mt-2"
      >
        <VCol class="text-center">
          <span>
            No {{ selectedPhotoType === 0 ? 'scoping' : 'installation' }} photos for this sign
          </span>
        </VCol>
      </VRow>
    </div>
  </VContainer>
</template>

<style lang="scss">
.scoping-photo-wrapper {
  text-align: center;
  .delete-scoping-photo-btn {
    width: calc(var(--v-btn-height));
    height: calc(var(--v-btn-height));
    margin-bottom: 5px;
    margin-left: auto;
    position: relative;
  }

  .scoping-photo-container {
    width: 200px;
    height: 200px;
    position: relative;
    display: inline-block;
  }

  .scoping-photo-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: zoom-in;
  }

  /* Hover effect: magnify and red shadow */
  .scoping-photo-img.hovered {
    opacity: 0.8;
    transform: scale(1.1); /* Magnify the image */
  }

  /* Magnify icon style */
  .magnify-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 65px;
    transition: opacity 0.3s ease;
    opacity: 1;
    pointer-events: none; /* Prevent interaction with the icon */
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6));
  }
}
</style>
