<script setup lang="ts">
import {} from 'vue'

interface IProps {
  modelValue?: boolean
  imageUrl?: string
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: false,
  imageUrl: '',
})

interface Emit {
  (e: 'update:modelValue', value: boolean): void
}

const emit = defineEmits<Emit>()

const closeDialog = () => {
  emit('update:modelValue', false)
}
</script>

<template>
  <VDialog
    :model-value="modelValue"
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
    @update:model-value="emit('update:modelValue')"
  >
    <template #default>
      <VContainer class="full-screen-container">
        <VImg
          :src="imageUrl"
          class="full-screen-image"
          alt="Full screen image"
        />
        <VBtn
          class="close-button"
          icon
          color="white"
          @click="closeDialog"
        >
          <VIcon icon="mdi-close" />
        </VBtn>
      </VContainer>
    </template>
  </VDialog>
</template>

<style scoped lang="scss">
.full-screen-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  padding: 16px; // Top and bottom margins
}

.full-screen-image {
  max-width: 100%;
  max-height: 100%;
  margin: 8px 0; // Add some top and bottom margin to the image
}

.close-button {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;

  border-radius: 0.375rem;
  background-color: rgb(var(--v-theme-surface)) !important;
  block-size: 2rem;
  inline-size: 2rem;
  inset-block-start: 0;
  inset-inline-end: 0;
  transform: translate(0.5rem, -0.5rem);
}
</style>
