import { transpose, multiply, lusolve } from 'mathjs'

// Define the type for geoPoint
interface GeoPoint {
  x: number
  y: number
  lon: number
  lat: number
}

// Define the type for item
interface Item {
  x: number
  y: number
  lon?: number
  lat?: number
  type?: string
  width?: number
  height?: number
  scaleX?: number
  scaleY?: number
}

// Function to solve a linear system of equations
function solveLinearSystem(A: number[][], B: number[]): number[] {
  // Transpose matrix A
  const AT = transpose(A) as number[][]
  // Compute A^T * A
  const ATA = multiply(AT, A) as number[][]
  // Compute A^T * B
  const ATB = multiply(AT, B) as number[]  // This is a vector
  // Solve the linear system
  return (lusolve(ATA, ATB) as number[][]).map(row => row[0])
}

// Function to update items with their lon and lat values
export function updateItemsLonLat(geoPoints: GeoPoint[], items: Item[]): void {
  // Extract x, y, lon, and lat arrays from geoPoints
  const x = geoPoints.map(p => p.x)
  const y = geoPoints.map(p => p.y)
  const lon = geoPoints.map(p => p.lon)
  const lat = geoPoints.map(p => p.lat)

  // Create matrix A for solving linear system for lon and lat coefficients
  const A = x.map((xi, i) => [xi, y[i], 1])
  // Solve linear system for lon and lat coefficients
  const lonCoeffs = solveLinearSystem(A, lon)
  const latCoeffs = solveLinearSystem(A, lat)

  // Update each item with its lon and lat values
  items.forEach(item => {
    let item_x = item.x
    let item_y = item.y
    // Update Signs to the center points
    if (item?.type === 'sign') {
      item_x += ((item?.width || 25) * (item?.scaleX || 1)) / 2
      item_y += ((item?.height || 25) * (item?.scaleY || 1)) / 2
    }
    // Calculate lon and lat values for the item
    item.lon = lonCoeffs[0] * item_x + lonCoeffs[1] * item_y + lonCoeffs[2]
    item.lat = latCoeffs[0] * item_x + latCoeffs[1] * item_y + latCoeffs[2]
  })
}
