<script lang="ts" setup>
import { useDrawingStore } from '@/store/drawing'
import {
  $addSignBlockPictograms,
  $createMultipleSignBlocks,
  $updateMultipleSignBlocks,
  $updateSignBlock,
  $updateSignBlockPictogram
} from '@/api/signs'
import Draggable from 'vuedraggable'
import SignBlockEditDialog from '@/components/projects/signs/sign-block/SignBlockEditDialog.vue'
import { useUserStore } from '@/store/user'
import { can } from '@layouts/plugins/casl'
import ability from '@/plugins/casl/ability'
import { useSnackbarStore } from '@/store/snackbar'

interface IProps {
  sign?: any,
  signSides?: number,
  glossaryItemsBySide?: any,
  isDrawing?: boolean,
  sides?: string[],
}

const props = withDefaults(defineProps<IProps>(), {
  sign: () => ({}),
  signSides: 1,
  glossaryItemsBySide: () => ({}),
  isDrawing: false,
  sides: () => (['A', 'B', 'C', 'D']),
})

interface Emit {
  (e: 'handleSignBlockData', value: any): void
  (e: 'getSignBlocks'): void
  (e: 'getActivityTimelineItems'): void
}

const emit = defineEmits<Emit>()

const drawingStore = useDrawingStore()
const userStore = useUserStore()
const snackbarStore = useSnackbarStore()

const alignmentOptions = [
  { value: 1, icon: 'tabler-align-left' },
  { value: 2, icon: 'tabler-align-center' },
  { value: 3, icon: 'tabler-align-right' }
]

const showGlossaryItemDialog = ref<boolean>(false)
const glossaryItemDialogIsPictogramOnly = ref<boolean>(false)
const glossaryItemDialogIsAddPictogram = ref<boolean>(false)
const glossaryItemDialogIsAddPictogramItem = ref<boolean>(false)
const glossaryItemDialogSelectedSignBlockId = ref<number | null>(null)
const glossaryItemDialogSelectedSignBlockPictogramId = ref<number | null>(null)

const showSignBlockEditDialog = ref<boolean>(false)
const selectedSignBlock = ref({})

const currentTab = ref(0)

const tabs = computed(() => {
  const result = []

  if (props.sign && props.sign.type_info) {
    for (let i = 0; i <= props.sign.type_info.sides; i++) {
      result.push(i)
    }
  }

  return result
})

const isShowGlossaryBlock = computed(() => {
  if (props.isDrawing) {
    return !drawingStore.offlineMode
  }
  return true
})

const addSignBlock = (isPictogram = false) => {
  showGlossaryItemDialog.value = true
  if (isPictogram) {
    glossaryItemDialogIsAddPictogramItem.value = true
  }
}

const updateSignBlockPictogram = async (selectedGlossaryItemIds: number[], isDeletePictogram = false) => {
  const [glossaryItemId] = selectedGlossaryItemIds
  const selectedSignBlockPictogramId = glossaryItemDialogSelectedSignBlockPictogramId.value

  // If no selectedSignBlockPictogramId - return
  if (!selectedSignBlockPictogramId) {
    return
  }

  const payload = {
    glossary_item_id: glossaryItemId,
    is_delete: isDeletePictogram,
  }
  try {
    // return Sign
    const res = await $updateSignBlockPictogram(selectedSignBlockPictogramId, payload)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}
const createSignBlocks = async (glossaryItemsIds: number[]) => {
  const payload = {
    sign: props.sign.id,
    side_number: currentTab.value,
    glossary_items_ids: glossaryItemsIds
  }
  try {
    // return Sign
    const res = await $createMultipleSignBlocks(payload)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

// Update Sign Blocks position numbers
const updateSignBlocksOrder = async () => {
  const signBlocks = props.glossaryItemsBySide[currentTab.value]
    .map((i: any, Idx: number) => ({id: i.id, position_number: Idx}))
  try {
    // return Sign(from the first SignBlock sent)
    const res = await $updateMultipleSignBlocks(signBlocks)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

const handleSignBlockData = (data: any) => {
  emit('handleSignBlockData', data)
  emit('getActivityTimelineItems')
}

const onCloseGlossaryItemDialog = () => {
  glossaryItemDialogIsPictogramOnly.value = false
  glossaryItemDialogIsAddPictogram.value = false
  glossaryItemDialogIsAddPictogramItem.value = false
  glossaryItemDialogSelectedSignBlockId.value = null
  glossaryItemDialogSelectedSignBlockPictogramId.value = null
}

const onClickSignBlockPictogram = (signBlockPictogramId: number) => {
  if (!userStore.isCustomer && ability.can('change', 'sign')) {
    showGlossaryItemDialog.value = true
    glossaryItemDialogIsPictogramOnly.value = true
    // change
    glossaryItemDialogIsAddPictogram.value = false
    // Pictogram ID
    glossaryItemDialogSelectedSignBlockPictogramId.value = signBlockPictogramId
  }
}

const onAddSignBlockPictogram = (signBlockId: number) => {
  if (!userStore.isCustomer && ability.can('change', 'sign')) {
    showGlossaryItemDialog.value = true
    glossaryItemDialogIsPictogramOnly.value = true
    // add
    glossaryItemDialogIsAddPictogram.value = true
    // Glossary Block ID
    glossaryItemDialogSelectedSignBlockId.value = signBlockId
  }
}

const addSignBlockPictograms = async (selectedGlossaryItemIds: number[]) => {
  const signBlockId = glossaryItemDialogSelectedSignBlockId.value

  // If no signBlockId - return
  if (!signBlockId) {
    return
  }

  const payload = {
    sign_block_id: signBlockId,
    glossary_item_ids: selectedGlossaryItemIds,
  }
  try {
    // return Sign
    const res = await $addSignBlockPictograms(payload)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

const onSelectAlignment = async (signBlockId: number, alignmentValue: number) => {
  try {
    await $updateSignBlock(signBlockId, { alignment: alignmentValue })
  } catch (e) {
    console.log(e)
  }
}

const calculatePictogramBlockWidth = (pictogramsCount: number | undefined) => {
  if (!pictogramsCount) return 0

  const MAX_VISIBLE = 4
  const SINGLE_WIDTH = 40

  const count = Math.min(pictogramsCount, MAX_VISIBLE)
  return `${count * SINGLE_WIDTH}px`
}
</script>

<template>
  <div v-if="isShowGlossaryBlock">
    <GlossaryItemDialog
      v-model="showGlossaryItemDialog"
      :glossary-group-id="sign?.campaign_glossary_group_id || null"
      :glossary-pictogram-catalogue-id="sign?.campaign_glossary_pictogram_catalogue_id || null"
      :is-add-pictogram="!!glossaryItemDialogIsAddPictogram"
      :is-add-pictogram-item="!!glossaryItemDialogIsAddPictogramItem"
      :is-pictogram-only="!!glossaryItemDialogIsPictogramOnly"
      @onClose="onCloseGlossaryItemDialog"
      @onCreateSignBlocks="createSignBlocks($event)"
      @onAddPictograms="addSignBlockPictograms($event)"
      @onDeletePictogram="updateSignBlockPictogram([], true)"
      @onUpdatePictogram="updateSignBlockPictogram($event)"
    />
    <SignBlockEditDialog
      v-model="showSignBlockEditDialog"
      :sign-block="selectedSignBlock"
      @onSubmit="emit('getSignBlocks'); emit('getActivityTimelineItems')"
    />
    <div
      v-if="!userStore.isCustomer && can('change', 'sign')"
      class="d-flex align-center flex-wrap justify-end"
    >
      <VBtn
        size="small"
        class="ma-1"
        @click="addSignBlock(true)"
      >
        <VIcon icon="mdi-add"/>
        Add Pictogram
      </VBtn>
      <VBtn
        size="small"
        class="ma-1"
        @click="addSignBlock(false)"
      >
        <VIcon icon="mdi-add"/>
        Add Glossary Item
      </VBtn>
    </div>
    <VTabs
      v-model="currentTab"
      bg-color="transparent"
      grow
    >
      <VTab
        v-for="tabIndex in tabs"
        :key="tabIndex"
        :value="tabIndex"
      >
        Side {{ sides[tabIndex] }}
      </VTab>
    </VTabs>

    <VWindow v-model="currentTab">
      <VWindowItem
        v-for="tabIndex in tabs"
        :key="tabIndex"
        :value="tabIndex"
      >
        <VCard
          flat
        >
          <VCardText
            v-if="glossaryItemsBySide[tabIndex].length"
            class="overflow-x-auto"
          >
            <Draggable
              :list="glossaryItemsBySide[tabIndex]"
              ghost-class="ghost-draggable"
              item-key="id"
              tag="table"
              class="sign-glossary-blocks-table"
              @change="updateSignBlocksOrder"
            >
              <template #item="{ element }">
                <tr class="position-relative">
                  <!-- Top-left corner block -->
                  <td class="relative-cell">
                    <div class="glossary-alignment-toggle-block-wrapper">
                      <VBtnToggle
                        v-model="element.alignment"
                        class="glossary-alignment-toggle-block"
                        color="primary"
                        density="compact"
                        mandatory
                        :rounded="false"
                        divided
                        @update:model-value="newVal => onSelectAlignment(element.id, newVal)"
                      >
                        <VBtn
                          v-for="(item, itemKey) in alignmentOptions"
                          :key="itemKey"
                          :value="item.value"
                          class="glossary-alignment-toggle-block-btn"
                          variant="outlined"
                          size=""
                        >
                          <VIcon :icon="item.icon" />
                        </VBtn>
                      </VBtnToggle>
                    </div>
                  </td>

                  <!-- Other table content -->
                  <td
                    :class="{
                      'text-left' : element.alignment === 1,
                      'text-center' : element.alignment === 2,
                      'text-right' : element.alignment === 3
                    }"
                    class="px-1 pt-7 pb-1"
                  >
                    <div
                      class="d-inline-flex align-center"
                      :class="{
                        'justify-start' : element.alignment === 1,
                        'justify-center' : element.alignment === 2,
                        'justify-end' : element.alignment === 3
                      }"
                    >
                      <span>{{ element.text_1 }}</span>
                      <span
                        :class="{
                          'pl-3' : element.text_1 || element.text_2,
                          'pr-3' : (element.text_1 || element.text_2) && element?.pictograms?.length
                        }"
                      >
                        {{ element.text_2 }}
                      </span>
                      <div :style="{ width: calculatePictogramBlockWidth(element?.pictograms?.length) }">
                        <VRow no-gutters>
                          <VCol
                            v-for="(pictogram, pictogramKey) in element.pictograms"
                            :key="pictogramKey"
                            class="position-relative pa-1 d-flex justify-center align-center cursor-pointer min-width-40"
                            cols="3"
                          >
                            <div
                              style="width: 30px; height: 30px"
                              class="d-flex align-center justify-center mx-0"
                              @click.stop="onClickSignBlockPictogram(pictogram.id)"
                            >
                              <img
                                :src="pictogram?.pic"
                                alt="icon"
                                style="width: 100%; height: 100%; object-fit: contain"
                              >
                            </div>
                            <VMenu
                              v-if="!userStore.isCustomer && can('change', 'sign')"
                              activator="parent"
                              class="default-tooltip"
                              close-delay="0"
                              location="top center"
                              offset="5"
                              open-delay="100"
                              open-on-hover
                            >
                              <span>
                                Update Pictogram
                              </span>
                            </VMenu>
                          </VCol>
                        </VRow>
                      </div>
                    </div>
                  </td>
                  <!-- Top-right corner block -->
                  <td
                    v-if="!userStore.isCustomer && can('change', 'sign')"
                    class="relative-cell"
                  >
                    <div class="add-pictogram-block-wrapper">
                      <VSheet
                        :height="24"
                        :width="24"
                        border
                        class="add-pictogram-block d-flex align-center justify-center"
                        rounded
                        @click.stop="onAddSignBlockPictogram(element.id)"
                      >
                        <VIcon
                          icon="mdi-plus"
                        />
                      </VSheet>
                      <VMenu
                        activator="parent"
                        class="default-tooltip"
                        close-delay="0"
                        location="top center"
                        offset="5"
                        open-delay="100"
                        open-on-hover
                      >
                        <span>
                          Add Pictogram
                        </span>
                      </VMenu>
                    </div>
                  </td>
                  <!-- Top-right corner block -->
                  <td
                    v-if="!userStore.isCustomer && can('change', 'sign')"
                    class="relative-cell"
                  >
                    <div class="edit-glossary-btn-wrapper">
                      <VBtn
                        class="edit-glossary-btn rounded-lg"
                        density="compact"
                        icon="tabler-edit"
                        variant="elevated"
                        size=""
                        @click="showSignBlockEditDialog = true; selectedSignBlock = { ...element }"
                      />
                      <VMenu
                        activator="parent"
                        class="default-tooltip"
                        close-delay="0"
                        location="top center"
                        offset="5"
                        open-delay="100"
                        open-on-hover
                      >
                        <span>
                          Edit
                        </span>
                      </VMenu>
                    </div>
                  </td>
                </tr>
              </template>
            </Draggable>
          </VCardText>
          <VCardText
            v-else
            class="text-center"
          >
            Glossary Items not found
          </VCardText>
        </VCard>
      </VWindowItem>
    </VWindow>
  </div>
</template>

<style lang="scss">
.sign-glossary-blocks-table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders don't double up */
  td, th {
    border-top: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
    border-bottom: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
  }

  .relative-cell {
    position: relative;
    width: 1px;
    padding: 0;
  }

  .glossary-alignment-toggle-block-wrapper {
    position: absolute;
    top: 5px;
    left: 0;
    z-index: 1;
  }

  .glossary-alignment-toggle-block {
    display: flex;
    height: 20px;
  }

  .glossary-alignment-toggle-block-btn {
    padding-left: 4px;
    padding-right: 4px;
  }

  .add-pictogram-block-wrapper {
    position: absolute;
    top: 2.5px;
    right: 30px;
    z-index: 1;
    .add-pictogram-block {
      cursor: pointer;
    }
  }
  .edit-glossary-btn-wrapper {
    position: absolute;
    top: 2.5px;
    right: 0;
    z-index: 1;
    .edit-glossary-btn {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
