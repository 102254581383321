<script setup>
import { useUserStore } from '@/store/user'
import { can } from '@layouts/plugins/casl'

import Img1 from '@images/home_bg_1.jpg'
import Img2 from '@images/home_bg_2.jpg'
import Img3 from '@images/home_bg_3.jpg'
import Img4 from '@images/home_bg_4.jpg'
import Img5 from '@images/home_bg_5.jpg'

// const navheight = ref('0px')
//
// onMounted(() => {
//   const navbarContainer = document.querySelector('.layout-navbar-and-nav-container')
//
//   navheight.value = `${navbarContainer.clientHeight}px`
// })

const images = [Img1, Img2, Img3, Img4, Img5]

const userStore = useUserStore()

const isMounted = ref(false)
const loading = ref(false)

const userItemsList = ref(null)
const userSignsList = ref(null)
const userKopItemsList = ref(null)

// Calculate the number of visible columns
const visibleColumns = computed(() => {
  return Object.values(userStore?.user?.campaign_access ?? {}).filter(Boolean).length
})

// Set column size based on the number of visible columns
const colSize = computed(() => {
  return 12
})

const lgSize = computed(() => {
  return visibleColumns.value === 3 ? 4 : (visibleColumns.value === 2 ? 6 : 12)
})

const mdSize = computed(() => {
  return visibleColumns.value === 3 ? 4 : (visibleColumns.value === 2 ? 6 : 12)
})

const maxItemsCount = computed(() => {
  const counts = [
    userItemsList.value?.itemsCount || 0,
    userSignsList.value?.itemsCount || 0,
    userKopItemsList.value?.itemsCount || 0
  ]

  // Check if all counts are numbers
  const allAreNumbers = counts.every(count => typeof count === 'number')

  // Return the maximum if all are numbers, otherwise return 0
  return allAreNumbers ? Math.max(...counts) : 0
})

onMounted(async () => {
  isMounted.value = true
})
</script>

<template>
  <div id="home-page-view">
    <template v-if="can('view', 'home')">
      <VOverlay
        v-model="loading"
        persistent
        absolute
        contained
        class="align-center justify-center"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VOverlay>
      <VRow v-if="!visibleColumns && isMounted">
        <VCol>
          <VCard
            class="pa-10 text-center text-h4"
          >
            No Available Projects
          </VCard>
        </VCol>
      </VRow>
      <VRow>
        <VCol
          v-if="userStore.isBrandingCampaignAccess"
          :cols="colSize"
          :lg="lgSize"
          :md="mdSize"
        >
          <BrandingItemsCard />
        </VCol>
        <VCol
          v-if="userStore.isWayfindingCampaignAccess"
          :cols="colSize"
          :lg="lgSize"
          :md="mdSize"
        >
          <WayfindingItemsCard />
        </VCol>
        <VCol
          v-if="userStore.isKopCampaignAccess"
          :cols="colSize"
          :lg="lgSize"
          :md="mdSize"
        >
          <KopItemsCard />
        </VCol>
      </VRow>
      <VRow>
        <VCol
          v-if="userStore.isBrandingCampaignAccess"
          :cols="colSize"
          :lg="lgSize"
          :md="mdSize"
        >
          <UserItemsList
            ref="userItemsList"
            :max-items-count="maxItemsCount"
          />
        </VCol>
        <VCol
          v-if="userStore.isWayfindingCampaignAccess"
          :cols="colSize"
          :lg="lgSize"
          :md="mdSize"
        >
          <UserSignsList
            ref="userSignsList"
            :max-items-count="maxItemsCount"
          />
        </VCol>
        <VCol
          v-if="userStore.isKopCampaignAccess"
          :cols="colSize"
          :lg="lgSize"
          :md="mdSize"
        >
          <UserKopItemsList
            ref="userKopItemsList"
            :max-items-count="maxItemsCount"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <UserActionRequired />
        </VCol>
      </VRow>
    </template>
    <template v-else>
      <VRow>
        <VCol>
          <VCard
            class="pa-10 text-center text-h4"
          >
            You don't have access to the home page
          </VCard>
        </VCol>
      </VRow>
    </template>
  </div>
</template>

<style lang="scss">
#home-page-view {
  .apexcharts-legend {
    height: 78px !important;
    max-height: 78px !important;
    align-content: baseline !important;
    .apexcharts-legend-series {
      .apexcharts-legend-text {
        color: rgba(var(--v-theme-on-surface)) !important;
      }
    }
  }
  .apexcharts-datalabels-group {
    .apexcharts-text {
      fill: rgba(var(--v-theme-on-surface)) !important;
    }
  }
}

@media (max-width: 1279.9px) {
  .layout-page-content {
    display: flex;
    // height - margin-top - padding-top - padding-bottom - navbar-height(dynamic ideally)
    min-height: calc(100% - 144px);
    height: 100%;
  }
}

.customer-container {
  display: flex;
  @media (max-width: 1279.9px) {
    flex: 1;
  }
  @media (min-width: 1280px) {
    height: 100%;
  }
  .customer-wrapper {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    align-items: end;
    justify-content: end;
    position: relative;
    min-height: 85vh;
    height: 100%;
    .customer-carousel {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      height: 100% !important;
    }
    .customer-home-card {
      position: absolute;
      left: 0;
      bottom: 24px;
      flex: 1;
      padding: 2rem;
      margin: 0 24px;
      background: rgba(0,0,0, 0.8);
      border-radius: 16px;
      color: #fff;
      align-self: end;
      justify-self: end;
      z-index: 3;
      width: 33%;
      h2 {
        color: #fff;
      }
    }
  }
}
</style>

<route lang="yaml">
meta:
  action: view
  subject: initial
</route>
