<script setup>
import { VDataTableServer, VDataTableFooter } from 'vuetify/labs/VDataTable'
import { $getSignTexts } from '@/api/settings'
import { debounce } from 'lodash'

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  glossaryGroupId: { type: Number, default: null },
  glossaryPictogramCatalogueId: { type: Number, default: null },
  isPictogramOnly: { type: Boolean, default: false },
  isAddPictogram: { type: Boolean, default: false },
  isAddPictogramItem: { type: Boolean, default: false }
})

const emit = defineEmits(['update:modelValue', 'onCreateSignBlocks', 'onUpdatePictogram', 'onAddPictograms',
  'onDeletePictogram', 'onClose'])

const glossaryItems = ref([])
const glossaryItemsLoading = ref(false)
const glossaryItemsCount = ref(0)
const glossaryItemsSearch = ref('')
const selectedGlossaryItemsIds = ref([])

const tableHeaders = [
  { key: 'id', title: 'ID', sortable: false, align: '' },
  { key: 'name', title: 'Name', sortable: false, align: '' },
  { key: 'text_1', title: 'Language 1', sortable: false, align: '' },
  { key: 'text_2', title: 'Language 2', sortable: false, align: '' },
  { key: 'pic', title: 'Pictogram', sortable: false, align: '' },
]

const computedTableHeaders = computed(() => {
  if (props.isPictogramOnly || props.isAddPictogram || props.isAddPictogramItem) {
    return tableHeaders.filter(i => ['id', 'name', 'pic'].includes(i.key))
  }
  return tableHeaders.filter(i => ['id', 'name', 'text_1', 'text_2'].includes(i.key))
})

const glossaryItemTableOptions = ref({
  itemsPerPage: 15,
  page: 1,
  sortBy: []
})

const itemsPerPageOptions = [
  { value: 10, title: '10' },
  { value: 15, title: '15' },
  { value: 20, title: '20' },
  { value: 50, title: '50' },
]

const getGlossaryItems = async () => {
  glossaryItemsLoading.value = true
  const filters = {
    page: glossaryItemTableOptions.value.page,
    per_page: glossaryItemTableOptions.value.itemsPerPage,
    search: glossaryItemsSearch.value,
  }

  if (props.isPictogramOnly || props.isAddPictogramItem || props.isAddPictogram) {
    // If no glossaryPictogramCatalogueId
    if (!props.glossaryPictogramCatalogueId) {
      glossaryItems.value = []
      glossaryItemsCount.value = 0
      glossaryItemsLoading.value = false
      return
    }

    filters.is_pictogram = true
    // Filter based on selected Campaign Glossary Pictogram Catalogue
    filters.pictogram_catalogue = props.glossaryPictogramCatalogueId ? props.glossaryPictogramCatalogueId : undefined
  } else {
    // If no glossaryGroupId - empty list
    if (!props.glossaryGroupId) {
      glossaryItems.value = []
      glossaryItemsCount.value = 0
      glossaryItemsLoading.value = false
      return
    }

    filters.is_pictogram = false
    filters.group = props.glossaryGroupId ? props.glossaryGroupId : undefined
  }
  try {
    const res = await $getSignTexts(filters)
    glossaryItems.value = res.data.results
    glossaryItemsCount.value = res.data.count
  } catch (e) {
    // If page doesn't exist goes to 1 page
    if (e.response.status === 404) {
      glossaryItemTableOptions.value.page = 1
      await getGlossaryItems()
    } else {
      console.log(e)
    }
  } finally {
    glossaryItemsLoading.value = false
  }
}

const closeDialog = () => {
  emit('update:modelValue', !props.modelValue)
}

const onCreateSignBlocks = () => {
  emit('onCreateSignBlocks', selectedGlossaryItemsIds.value)
  closeDialog()
}

const onAddPictograms = () => {
  emit('onAddPictograms', selectedGlossaryItemsIds.value)
  closeDialog()
}

const onUpdatePictogram = () => {
  emit('onUpdatePictogram', selectedGlossaryItemsIds.value)
  closeDialog()
}

const onDeletePictogram = () => {
  emit('onDeletePictogram')
  closeDialog()
}

const onUpdateTableOptions = async ({ page, itemsPerPage, sortBy }) => {
  glossaryItemTableOptions.value.page = page
  glossaryItemTableOptions.value.itemsPerPage = itemsPerPage
  glossaryItemTableOptions.value.sortBy = sortBy
  await getGlossaryItems()
}

const onGlossaryItemsSearch = debounce(() => { getGlossaryItems() }, 500)

const onClose = async () => {
  // clear selected after hiding dialog
  selectedGlossaryItemsIds.value = []
  glossaryItemTableOptions.value.page = 1
  glossaryItemsSearch.value = ''
  emit('onClose')
}
</script>

<template>
  <VDialog
    :model-value="modelValue"
    max-width="600"
    class="default-dialog"
    @after-leave="onClose"
    @update:modelValue="emit('update:modelValue')"
  >
    <VBtn
      variant="elevated"
      density="compact"
      icon="tabler-x"
      class="v-dialog-close-btn"
      @click="closeDialog"
    />

    <VCard>
      <template #title>
        Glossary
        {{ isPictogramOnly || isAddPictogramItem ? 'Pictograms' : 'Items' }}
      </template>
      <VCardText>
        <VRow>
          <VCol class="py-0">
            <VTextField
              v-model="glossaryItemsSearch"
              placeholder="Search by Name, Text"
              @input="onGlossaryItemsSearch"
            >
              <template #prepend-inner>
                <VIcon
                  icon="tabler-search"
                  size="20"
                />
              </template>
            </VTextField>
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            class="py-0 mt-1"
          >
            <VDataTableServer
              v-model="selectedGlossaryItemsIds"
              v-model:page="glossaryItemTableOptions.page"
              :headers="computedTableHeaders"
              :items="glossaryItems"
              :items-length="glossaryItemsCount"
              :loading="glossaryItemsLoading"
              no-data-text="Glossary Items not found"
              hover
              item-value="id"
              density="compact"
              :select-strategy="isPictogramOnly && !isAddPictogram ? 'single' : 'page'"
              show-select
              :items-per-page="glossaryItemTableOptions.itemsPerPage"
              @update:options="onUpdateTableOptions"
            >
              <template #item.pic="{ item }">
                <div
                  style="width: 50px; height: 50px"
                  class="d-flex align-center justify-center"
                >
                  <img
                    :src="item.raw.pic"
                    alt="icon"
                    style="width: 100%; height: 100%; object-fit: contain"
                  >
                </div>
              </template>
              <template #bottom>
                <div class="glossary-items-data-table-footer">
                  <VDataTableFooter
                    :items-per-page-options="itemsPerPageOptions"
                    show-current-page
                    class="mt-1"
                  />
                </div>
              </template>
            </VDataTableServer>
          </VCol>
          <VCol
            class="d-flex justify-space-between py-0"
            cols="12"
          >
            <div>
              <VBtn
                v-if="isPictogramOnly && !isAddPictogram"
                variant="outlined"
                append-icon="mdi-minus-circle"
                @click="onDeletePictogram"
              >
              <span class="text-none">
                Delete pictogram
              </span>
              </VBtn>
            </div>
            <VBtn
              v-if="isAddPictogram"
              :disabled="!selectedGlossaryItemsIds.length"
              @click="onAddPictograms"
            >
              <span class="text-none">
                Add pictogram(s)
              </span>
            </VBtn>
            <VBtn
              v-else-if="isPictogramOnly"
              :disabled="!selectedGlossaryItemsIds.length"
              @click="onUpdatePictogram"
            >
              <span class="text-none">
                Update pictogram
              </span>
            </VBtn>
            <VBtn
              v-else
              :disabled="!selectedGlossaryItemsIds.length"
              @click="onCreateSignBlocks"
            >
              <span class="text-none">
                Add {{ selectedGlossaryItemsIds.length }}
                {{ isAddPictogramItem ? 'pictogram(s)' : 'item(s)' }}
              </span>
            </VBtn>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style lang="scss">
.glossary-items-data-table-footer {
  .v-data-table-footer {
    justify-content: center !important;
  }
}
</style>
