<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable'
import { useDisplay } from 'vuetify'
import {
  ActionRequiredRowClickEvent,
  ChangeRequestActionRequiredItem,
  ChangeRequestTableOptions,
  ChangeRequestTableSorting,
} from '@/models/changeRequest'
import { $getChangeRequestActionRequiredItems } from '@/api/changeRequests'

interface IProps {
  cardTitle: string,
  initialItemsPerPage: number,
}

const props = withDefaults(defineProps<IProps>(), {
  cardTitle: 'Change Request Items Assigned To You',
  initialItemsPerPage: 10,
})

interface CustomEvents {
}

const emit = defineEmits<CustomEvents>()

const vuetifyDisplay = useDisplay

const router = useRouter()

const changeRequestTableHeaders = [
  { key: 'code', title: 'Code', sortable: true },
  { key: 'date_created', title: 'Date Created', sortable: true },
  { key: 'activity_required', title: 'Activity Required', sortable: true },
  { key: 'project_type_display', title: 'Project Type', sortable: true },
  { key: 'project_name', title: 'Project', sortable: true },
  { key: 'assigned_to_users', title: 'Assigned To', sortable: true },
  { key: 'status_display', title: 'Status', sortable: true },
  { key: 'requestor_name', title: 'Requestor', sortable: true },
  { key: 'due_date', title: 'Due Date', sortable: true },
  { key: 'file_attachments', title: 'File Attachments', sortable: false },
]

const changeRequestTableOptions = ref<ChangeRequestTableOptions>({
  itemsPerPage: props.initialItemsPerPage,
  page: 1,
  sortBy: []
})

const itemsPerPageOptions = [
  { value: 10, title: '10' },
  { value: 15, title: '15' },
  { value: 20, title: '20' },
  { value: 50, title: '50' },
]

const isMounted = ref<boolean>(false)

const changeRequests = ref<ChangeRequestActionRequiredItem[]>([])
const changeRequestsCount = ref<number>(0)
const changeRequestsLoading = ref<boolean>(false)

const gerOrdering = () => {
  // Define default ordering
  let ordering = 'due_date'

  // Check if sortBy is defined and has at least one item
  if (changeRequestTableOptions.value.sortBy.length > 0) {
    const sortBy = changeRequestTableOptions.value.sortBy[0]
    const sortByKey = sortBy.key
    const sortOrder = sortBy.order === 'desc' ? '-' : '' // Determine the sorting order

    ordering = `${sortOrder}${sortByKey}`
  }

  return ordering
}

const getChangeRequests = async () => {
  changeRequestsLoading.value = true

  const ordering = gerOrdering()

  try {
    const res = await $getChangeRequestActionRequiredItems({
      page: changeRequestTableOptions.value.page,
      per_page: changeRequestTableOptions.value.itemsPerPage,
      ordering: ordering,
    })
    changeRequests.value = res.data.results
    changeRequestsCount.value = res.data.count
  } catch (e: any) {
    // If page doesn't exist goes to 1 page
    if (e?.response?.status === 404) {
      changeRequestTableOptions.value.page = 1
      await getChangeRequests()
    } else {
      console.log(e)
    }
  } finally {
    changeRequestsLoading.value = false
  }
}

onMounted(() => {
  getChangeRequests()

  isMounted.value = true
})

const onUpdateTableOptions = async ({ page, itemsPerPage, sortBy }: { page: number; itemsPerPage: number; sortBy: ChangeRequestTableSorting[] }) => {
  changeRequestTableOptions.value.page = page
  changeRequestTableOptions.value.itemsPerPage = itemsPerPage
  changeRequestTableOptions.value.sortBy = sortBy
  if (isMounted.value) {
    await getChangeRequests()
  }
}

const onRowClick = async (e: MouseEvent, payload: ActionRequiredRowClickEvent) => {
  const { item } = payload
  // View Change Request
  await router.push({ path: `/change-requests/${item.raw.current_change_request_id}` })
}
</script>

<template>
  <VCard :title="cardTitle">
    <VCardText>
      <VOverlay
        v-model="changeRequestsLoading"
        persistent
        absolute
        contained
        class="align-center justify-center"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VOverlay>
      <VDataTableServer
        v-model:page="changeRequestTableOptions.page"
        :items="[ ...changeRequests ]"
        :items-length="parseInt(String(changeRequestsCount), 10)"
        :headers="changeRequestTableHeaders"
        :loading="!!changeRequestsLoading"
        no-data-text="Change Requests not found"
        density="compact"
        hover
        show-current-page
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page="changeRequestTableOptions.itemsPerPage"
        @update:options="onUpdateTableOptions"
        @click:row="onRowClick"
      >
        <template #item.file_attachments="{ item }">
          <!-- Check if item.raw.file_attachments is an array -->
          <div v-if="Array.isArray(item.raw.file_attachments)">
            <a
              v-for="file in item.raw.file_attachments"
              :key="file.id"
              :href="file.file"
              target="_blank"
              @click.stop
            >
              <!-- Display an icon for each file -->
              <VIcon icon="tabler-file-description" />
            </a>
          </div>
          <!-- If item.raw.file_attachments is not an array, show a Empty(dash) -->
          <div v-else>
            -
          </div>
        </template>
        <template #item.actions="{ item }">
        </template>
      </VDataTableServer>
    </VCardText>
  </VCard>
</template>
